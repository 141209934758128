import Vue from 'vue';
import App from './App';
import Vuetify from 'vuetify';
import router from './router';
import store from './store';
import i18n from './i18n';
import lodash from 'lodash';
import Luxon from './luxon';
import Formatters from './utils/formatters';
import Validations from './utils/validations';
import VueScrollTo from 'vue-scrollto';
import VueSignaturePad from 'vue-signature-pad';
import HIcon from '@/components/HIcon'
//import 'material-icons/iconfont/material-icons.css';
import HIcon2 from '@/components/HIcon2';
import themes from './assets/css/themes';
import icons from './assets/icons/icons';


import 'tinymce';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/table';

/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';

/* A theme is also required */
import 'tinymce/themes/silver';


//import 'material-icons/iconfont/material-icons.css';
import 'vuetify/dist/vuetify.min.css';

import VueTippy, { TippyComponent } from 'vue-tippy';
import 'tippy.js/themes/light-border.css';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  theme: 'light-border',
  ignoreAttributes: true,
  duration: [500, 100],
  placement: 'top',
  arrow: true,
  arrowType: 'round',
  animation: 'fade',
  delay: 50,
  boundary: 'window',
});

Vue.component('tippy', TippyComponent);
Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(Luxon);
Vue.use(VueTelInput);
Vue.use(Formatters);
Vue.use(Validations);
Vue.use(VueSignaturePad);
Vue.component('h-icon', HIcon);
Vue.component('h-icon2', HIcon2);

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease-in-out',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Object.defineProperty(Vue.prototype, '$_', { value: lodash });


const vuetify = new Vuetify({
  icons: icons,
  theme: {
    options: {
      customProperties: true,
    },
    themes: themes,
  },
});


new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
