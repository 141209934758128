<template>
	<div v-if="isReady">
		<div style="border-bottom: 1px solid var(--v-gray_30-base)" class="mx-2 py-4">
			<div class="row-format gap-2 ml-3 pt-2">
				<v-select
					style="max-width: 200px"
					v-if="view === 'overview'"
					:attach="true"
					dense
					hide-details
					v-model="filter"
					:items="filterOptions"
					label="Project status"
				></v-select>
				<div class="row-format gap-3" v-if="view !== 'overview'">
					<v-text-field
						style="max-width: 200px; font-size: 12px!important;"
						dense
						hide-details
						persistent-placeholder
						v-model="taskFilter"
						label="Filter"
						placeholder="Search..."
					></v-text-field>
					<!--v-select
						dense
						hide-details
						persistent-placeholder
						style="max-width: 150px"
						v-model="taskStatus"
						:items="['Active', 'Archived', 'All']"
						label="Task status"
					></v-select-->
					<v-autocomplete
						dense
						hide-details
						persistent-placeholder
            style="max-width: 250px; font-size: 12px!important;"
            v-model="projectIdFilter"
						:items="projectsByType"
						item-text="name"
						item-value="id"
            label="Project"
					></v-autocomplete>
					<v-select
						v-if="projectTypes.length > 1"
						dense
						hide-details
						persistent-placeholder
						style="max-width: 200px; font-size: 12px!important;"
						v-model="projectTypeId"
						:items="projectTypes"
						item-text="name"
						item-value="id"
						label="Project type"
						@change="saveProjectTypeId"
					></v-select>
				</div>
			</div>
		</div>
		<div :style="`height: calc(100vh - ${headerHeight}px)`" class="mx-0 pt-4 show-scrollbar" id="project-container">
			<div v-if="view === 'overview'" class="mx-3">
				<project
					v-for="project in filteredProjects"
					:key="project.id"
					:project="project"
					:status-list="getProjectType(project.projectTypeId).statusList"
					class="project-wrapper"
				></project>
			</div>
			<div v-if="view === 'kanban' && fullReadProjects.length > 0">
				<project-kanban
					:assignable-users="assignableUsers"
					:has-full-access-projects="fullAccessProjects.length > 0"
					:deliverables="filteredDeliverables"
					:is-collaborator="true"
					:columns="activeProjectType.statusList"
					:header-height="headerHeight"
					@edit-deliverable="editDeliverable($event)"
					@update-kanban-props="updateKanbanProperties($event)"
					@add-deliverable="addDeliverable($event)"
					@add-subtask="createSubtask($event)"
				></project-kanban>
			</div>
			<div v-if="view === 'timeline' && fullReadProjects.length > 0" class="show-scrollbar mr-3" style="height: 100%">
				<gantt-view
					:assignable-users="assignableUsers"
					:deliverables="filteredDeliverables"
					:status-list="activeProjectType.statusList"
					:header-height="headerHeight"
					@edit-deliverable="editDeliverable($event)"
					@dates-updated="datesUpdated($event)"
				></gantt-view>
			</div>

			<div v-if="view === 'calendar' && fullReadProjects.length > 0" class="show-scrollbar mr-3" style="height: 100%">
				<project-calendar
					:projects="filteredProjects"
					:deliverables="filteredDeliverables"
					:status-list="activeProjectType.statusList"
					@edit-deliverable="editDeliverable($event)"
					@update-due-date="datesUpdated($event)"
				>
				</project-calendar>
			</div>
		</div>
	</div>
</template>

<script>
	import Project from '@/modules/projects/Project';
	import ProjectService from '@/modules/projects/ProjectService';
	import ProjectKanban from '@/modules/projects/management/ProjectKanban';
	import GanttView from '@/modules/projects/management/GanttView';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
	import ProjectSelector from '@/modules/projects/ProjectSelector';
	import ProjectCalendar from '@/modules/projects/management/ProjectCalendar';

	export default {
		name: 'Projects',

		props: ['v'],

		components: { ProjectCalendar, GanttView, ProjectKanban, Project },

		data: function() {
			return {
				projectService: new ProjectService(),
				view: 'overview',
				projects: [],
				deliverables: [],
				projectTypes: [],
				projectTypeId: null,
        projectIdFilter: null,
				assignableUsers: [],
				filter: this.$t('projects.filter.active'),
				taskFilter: null,
				taskStatus: 'Active',
				headerHeight: 130,
				isReady: false,
				filterOptions: [
					this.$t('projects.filter.active'),
					this.$t('projects.filter.complete'),
					this.$t('projects.filter.all'),
				],
			};
		},

		mounted() {
			if (this.v) {
				this.view = this.v;
			}

			this.getProjectList();
			this.getAssignableUsers();
			this.$store.state.eventBus.$on(this.getChannelName(), this.eventHandler);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.getChannelName(), this.eventHandler);
		},

		methods: {
			setView: function(view) {
				this.view = view;
				window.history.pushState({}, document.title, this.$router.currentRoute.path + `?v=${view}`);
			},

			saveProjectTypeId: function() {
				localStorage.setItem('PROJECT_TYPE_ID', this.projectTypeId);
			},

			getAssignableUsers: function() {
				this.projectService.getAllAssignableUsers().then((res) => {
					this.assignableUsers.splice(0, this.assignableUsers.length);
					this.assignableUsers.push(...res.data);
				});
			},

			getProjectList: function() {
				let projectId = this.$route.query.projectId;

				this.projectService.getProjects().then((res) => {
					this.projects.splice(0, this.projects.length);
					this.projects.push(...res.data);
					this.projects = this.projects.sort(this.sortByActive);
					this.projects = this.projects.sort(this.sortByName);

					if (projectId) {
						this.projects.forEach((p) => {
							if (p.id === projectId) {
								p.initExpanded = true;
							}
						});
					}

					if (this.projects.length && !projectId) {
						this.projects[0].initExpanded = true;
					}

					this.getProjectTypes();
					this.fixupTabs();
				});
			},

			getProjectTypes: function() {
				this.projectService.getProjectTypes().then((res) => {
					let savedProjectTypeId = localStorage.getItem('PROJECT_TYPE_ID');
					this.projectTypes.push(...res.data);

          if(this.projectTypes.length === 0){
            return;
          }

					if (savedProjectTypeId) {
						if (this.projectTypes.find((p) => p.id === savedProjectTypeId)) {
							this.projectTypeId = savedProjectTypeId;
						}
					}

					if (!this.projectTypeId) {
						let defaultProjectType = this.projectTypes.find((p) => p.isDefaultProjectType);

            if(defaultProjectType) {
              this.projectTypeId = defaultProjectType.id;
            }else{
              this.projectTypeId = this.projectTypes[0].id;
            }
          }

					this.getDeliverables();
					this.isReady = true;
				});
			},

			fixupTabs: function() {
				if (!this.hasProjectManagement) {
					this.$store.state.tabs.splice(1, this.$store.state.tabs.length);
				}
			},

			getDeliverables: function() {
				this.projectService.getDeliverables(this.projectTypeId).then((res) => {
					this.deliverables.splice(0, this.deliverables.length);
					this.deliverables.push(...res.data);
					this.deliverables.forEach((d) => {
						d.project = this.projects.find((p) => p.id === d.projectId);
					});
				});
			},

			addDeliverable: function(column) {
				if (this.fullAccessProjects.length > 1) {
					this.$store.state.globalModalController
						.openModal(ProjectSelector, { projects: this.fullAccessProjects })
						.then((res) => {
							if (res) {
								this.createDeliverable(res.project, column);
							}
						});
				} else {
					this.createDeliverable(this.fullAccessProjects[0], column);
				}
			},

			createSubtask: function(parentTask) {
				let deliverable = {
					id: null,
					name: null,
					parentTaskId: parentTask.id,
					description: null,
					assignedTo: null,
					dueDate: null,
					statusId: this.activeProjectType.statusList[0].id,
					tasks: [],
					comments: [],
					events: [],
					files: [],
					archived: false,
				};
				this.$store.commit('startLoading');
				this.projectService
					.createDeliverable(parentTask.project.id, deliverable)
					.then((res) => {
						this.editDeliverable(res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			createDeliverable: function(project, column) {
				let deliverable = {
					id: null,
					name: null,
					description: null,
					assignedTo: null,
					dueDate: null,
					statusId: column.id,
					tasks: [],
					comments: [],
					events: [],
					files: [],
					archived: false,
				};
				this.$store.commit('startLoading');
				this.projectService
					.createDeliverable(project.id, deliverable)
					.then((res) => {
						this.editDeliverable(res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			updateKanbanProperties: function(updates) {
				this.projectService.updateKanbanProperties(updates);
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.pr';
			},

			eventHandler: function(event) {
				if (event.userMetadata === 'ProjectDeliverableMini') {
					this.processDeliverableDataEvent(event.message);
				} else if (event.userMetadata === 'KanbanUpdate') {
					this.processKanbanUpdate(event.message);
				}
			},

			processKanbanUpdate: function(updates) {
				for (let i = 0; i < updates.length; i++) {
					let deliverableId = updates[i].deliverableId;
					let kanbanSort = updates[i].kanbanSort;
					let statusId = updates[i].statusId;

					let ix = this.deliverables.findIndex((d) => d.id === deliverableId);

					if (ix > -1) {
						let deliverable = this.deliverables[ix];
						deliverable.kanbanSort = kanbanSort;
						deliverable.statusId = statusId;
						this.deliverables.splice(ix, 1, deliverable);
					}
				}
			},

			processDeliverableDataEvent: function(deliverable) {
				let project = this.projects.find((p) => p.id === deliverable.projectId);

				if (!project) {
					return;
				}

				deliverable.project = project;

				let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);
				if (ix > -1) {
					if (deliverable.statusId === '__deleted__') {
						this.deliverables.splice(ix, 1);
					} else {
						this.deliverables.splice(ix, 1, deliverable);
					}
				} else if (deliverable.statusId !== '__deleted__') {
					this.deliverables.push(deliverable);
				}
			},

			editDeliverable: function(deliverable) {
				let project = this.projects.find((p) => p.id === deliverable.projectId);
				this.projectService.getDeliverableDetail(deliverable.id).then((res) => {
					let binding = {
						origDeliverable: res.data,
						project: project,
						statusList: this.activeProjectType.statusList,
					};
					this.$store.state.globalModalController.openModal(DeliverableDetail, binding).then((res) => {
						if (res) {
							res.project = project;
							let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);
							if (ix > -1) {
								this.deliverables.splice(ix, 1, res);
							} else {
								this.deliverables.push(res);
							}
						}
					});
				});
			},

			datesUpdated: function(dates) {
				let patch = [];
				let ix = this.deliverables.findIndex((d) => d.id === dates.id);
				let deliverable = this.deliverables[ix];

				if (deliverable.project.portalAccess === 'Full access') {
					deliverable.startDate = dates.startDate;
					deliverable.dueDate = dates.dueDate;
					this.deliverables.splice(ix, 1, deliverable);

					patch.push({
						op: 'replace',
						path: '/dueDate',
						value: dates.dueDate,
					});

					patch.push({
						op: 'replace',
						path: '/startDate',
						value: dates.startDate,
					});

					this.projectService.patchDeliverable(deliverable.id, patch);
				} else {
					this.deliverables.splice(ix, 1, deliverable);
				}
			},

			getDefaultProjectType: function() {
				return this.projectTypes.find((p) => p.isDefaultProjectType);
			},

			getProjectType: function(projectTypeId) {
				let ix = this.projectTypes.findIndex((p) => p.id === projectTypeId);
				if (ix > -1) {
					return this.projectTypes[ix];
				} else {
					return this.getDefaultProjectType();
				}
			},

			sortByActive: function(a, b) {
				if (a.active && b.active) {
					return 0;
				} else if (a.active) {
					return -1;
				} else if (b.active) {
					return 1;
				} else {
					return 0;
				}
			},

			sortByName: function(a, b) {
				return a.name.localeCompare(b.name);
			},

			isInTaskStatusFilter(d) {
				if (this.taskStatus === 'Active' && !d.archived) {
					return true;
				} else if (this.taskStatus === 'Archived' && d.archived) {
					return true;
				} else if (this.taskStatus === 'All') {
					return true;
				} else {
					return false;
				}
			},

			isInNameFilter(d) {
				if (this.taskFilter) {
					return d.name && d.name.toLowerCase().includes(this.taskFilter.toLowerCase());
				} else {
					return true;
				}
			},
		},

		computed: {
			activeProjectType: function() {
				return this.projectTypes.find((p) => p.id === this.projectTypeId);
			},

			filteredDeliverables: function() {
				let protectedIds = new Set();
				let result = [...this.deliverables.filter((d) => d.projectTypeId === this.projectTypeId)];
				let subTaskMap = new Map();

				//get all the subtasks to the bottom, so they get processed first as we loop the list backwards
				result.sort((a, b) => {
					if (a.parentTaskId === null && b.parentTaskId !== null) {
						return -1;
					} else if (a.parentTaskId !== null && b.parentTaskId === null) {
						return 1;
					} else {
						return 0;
					}
				});

				for (let i = result.length - 1; i >= 0; i--) {
					let deliverable = result[i];

					if (protectedIds.has(deliverable.id)) {
						//skip filtering as we are needed based on matching subtask
					} else if (!this.isInTaskStatusFilter(deliverable)) {
						result.splice(i, 1);
						continue;
					} else if (!this.isInNameFilter(deliverable)) {
						result.splice(i, 1);
						continue;
					}else if(this.projectIdFilter != null && deliverable.projectId !== this.projectIdFilter){
            result.splice(i, 1);
            continue;
          }

					if (deliverable.parentTaskId) {
						protectedIds.add(deliverable.parentTaskId);
						result.splice(i, 1);

						if (!subTaskMap.has(deliverable.parentTaskId)) {
							subTaskMap.set(deliverable.parentTaskId, []);
						}

						subTaskMap.get(deliverable.parentTaskId).push(deliverable);
					} else {
						if (deliverable.subtasks) {
							deliverable.subtasks.splice(0, deliverable.subtasks.length);
						} else {
							this.$set(deliverable, 'subtasks', []);
						}
					}
				}

				let deliverableMap = new Map(result.map((obj) => [obj.id, obj]));

				subTaskMap.forEach((value, key) => {
					if (deliverableMap.has(key)) {
						//push into subtask array
						deliverableMap.get(key).subtasks.push(...value);
					} else {
						//failsafe to surface subtasks if they get orphaned
						deliverableMap.set(value.id, value);
					}
				});

				result = [...deliverableMap.values()];
				return result;
			},

			filteredProjects: function() {
				let result = [...this.projects];
				return result
					.filter((p) => {
						if (this.filter === 'Active') {
							return p.active === true;
						} else if (this.filter === 'Complete') {
							return p.active === false;
						} else {
							return true;
						}
					})
					.sort(this.sortByActive)
					.sort(this.sortByName);
			},

			projectsByType: function() {
				let result = [...this.fullReadProjects.filter((p) => p.projectTypeId === this.projectTypeId)];
        result.unshift({
          id: null,
          name: '-- All projects --'
        })
        return result;
			},

			fullAccessProjects: function() {
				return this.projects.filter((p) => p.active && p.portalAccess === 'Full access');
			},

			fullReadProjects: function() {
				return this.projects.filter(
					(p) => p.active && (p.portalAccess === 'Full access' || p.portalAccess === 'Read only')
				);
			},

			hasProjectManagement: function() {
				return this.projects.filter((p) => p.portalAccess !== 'Overview').length > 0;
			},
		},

		watch: {
			projectTypeId: function() {
				this.deliverables.splice(0, this.deliverables.length);
        this.projectIdFilter = null;
				this.getDeliverables();
			},

			v: function(val) {
				this.setView(val);
			},
		},
	};
</script>

<style scoped lang="scss">
	.project-wrapper {
		min-height: 42px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		margin-bottom: 16px;
	}
</style>
