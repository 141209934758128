import DateTime from '@/modules/utils/HDateTime';
import WebFontLoader from "webfontloader";

export default {
	data: function() {
		return {
			timeUnits: [
				{ text: 'Days', value: 'DAYS' },
				{ text: 'Weeks', value: 'WEEKS' },
				{ text: 'Months', value: 'MONTHS' },
			],
		};
	},

	methods: {
		loadFonts: function(agreement){
			let fonts = new Set();

			fonts.add(agreement.styles.body.fontFamily);
			fonts.add(agreement.styles.h1.fontFamily);
			fonts.add(agreement.styles.h2.fontFamily);
			fonts.add(agreement.styles.h3.fontFamily);
			let families =  [];

			fonts.forEach(f => {
				families.push(`${f}:100,200,300,400,500,600,700,800`);
			});

			console.log('loading',families);
			
			WebFontLoader.load({
				google: {
					families: families,
				},
				active: () => console.log('fonts are active'),
			});
		},

		checkNull: function(val) {
			return val ? val : '';
		},

		getTokensFromAgreement: function(agreement) {
			let result = new Map();

			if (agreement) {
				result.set('Agreement.Name',agreement.name)
				result.set('Agreement.DateCreated',DateTime.fromISO(agreement.dateCreated).toLocaleString(DateTime.DATE_MED));
				result.set('Agreement.DateCreatedFull',DateTime.fromISO(agreement.dateCreated).toLocaleString(DateTime.DATE_FULL));
				if(agreement.dateCompleted) {
					result.set('Agreement.DateSigned', DateTime.fromISO(agreement.dateCompleted).toLocaleString(DateTime.DATE_MED));
					result.set('Agreement.DateSignedFull', DateTime.fromISO(agreement.dateCompleted).toLocaleString(DateTime.DATETIME_FULL));
				}else{
					result.set('Agreement.DateSigned',DateTime.now().toLocaleString(DateTime.DATE_MED));
					result.set('Agreement.DateSignedFull',DateTime.now().toLocaleString(DateTime.DATE_FULL));
				}
				result.set('Account.Name', this.checkNull(agreement.account.accountName));
				result.set('Account.TaxId', this.checkNull(agreement.account.taxId));
				result.set('Account.Address1', this.checkNull(agreement.account.address1));
				result.set('Account.Address2', this.checkNull(agreement.account.address2));
				result.set('Account.City', this.checkNull(agreement.account.city));
				result.set('Account.Locality', this.checkNull(agreement.account.locality));
				result.set('Account.Postal', this.checkNull(agreement.account.postal));
				result.set('Account.Country', this.checkNull(agreement.account.country));
				result.set('Account.Phone', this.checkNull(agreement.account.phone));
				result.set('Account.Website', this.checkNull(agreement.account.website));

				result.set('Client.Name', this.checkNull(agreement.client?.name));
				result.set('Client.TaxId', this.checkNull(agreement.client?.taxId));
				result.set('Client.Address1', this.checkNull(agreement.client?.address1));
				result.set('Client.Address2', this.checkNull(agreement.client?.address2));
				result.set('Client.City', this.checkNull(agreement.client?.city));
				result.set('Client.Locality', this.checkNull(agreement.client?.locality));
				result.set('Client.Postal', this.checkNull(agreement.client?.postal));
				result.set('Client.Country', this.checkNull(agreement.client?.country));
				result.set('Client.Phone', this.checkNull(agreement.client?.phone));
				result.set('Client.Website', this.checkNull(agreement.client?.website));
				result.set('Client.TaxRate',this.getTaxRate(agreement));
				result.set('Client.HourlyRate',this.$formatters.dollars(agreement.client?.hourlyAmount, true, true, agreement.currency));

				this.clientFields.forEach((f) => {
					let value = agreement.client?.customValues.find((v) => v.mappingKey === f.mappingKey);
					if(f.type === 'Date' && value && value.value){
						result.set('Client.Custom.' + f.mappingKey, DateTime.fromISO(value.value).toLocaleString(DateTime.DATE_FULL));
					}else {
						result.set('Client.Custom.' + f.mappingKey, value ? value.value : '');
					}
				});

				result.set('Opportunity.Name',this.checkNull(agreement.opportunity?.name));
				result.set('Opportunity.Description',this.checkNull(agreement.opportunity?.description));
				result.set('Opportunity.FirstName',this.checkNull(agreement.opportunity?.formData?.firstName));
				result.set('Opportunity.LastName',this.checkNull(agreement.opportunity?.formData?.lastName));
				result.set('Opportunity.Email',this.checkNull(agreement.opportunity?.formData?.email));
				result.set('Opportunity.Phone',this.checkNull(agreement.opportunity?.formData?.phone));
				result.set('Opportunity.Role',this.checkNull(agreement.opportunity?.formData?.role));
				result.set('Opportunity.BusinessName',this.checkNull(agreement.opportunity?.formData?.businessName));
				result.set('Opportunity.Website',this.checkNull(agreement.opportunity?.formData?.website));
				result.set('Opportunity.Address1',this.checkNull(agreement.opportunity?.formData?.address1));
				result.set('Opportunity.Address2',this.checkNull(agreement.opportunity?.formData?.address2));
				result.set('Opportunity.City',this.checkNull(agreement.opportunity?.formData?.city));
				result.set('Opportunity.Locality',this.checkNull(agreement.opportunity?.formData?.locality));
				result.set('Opportunity.Postal',this.checkNull(agreement.opportunity?.formData?.postal));
				result.set('Opportunity.Country',this.checkNull(agreement.opportunity?.formData?.country));

				this.pipelineFields.forEach((f) => {
					let value = agreement.opportunity?.customValues.find((v) => v.mappingKey === f.mappingKey);

					if(f.type === 'Date' && value && value.value){
						result.set('Opportunity.Custom.' + f.mappingKey, DateTime.fromISO(value.value).toLocaleString(DateTime.DATE_FULL));
					}else {
						result.set('Opportunity.Custom.' + f.mappingKey, value ? value.value : '');
					}
				});

				agreement.signers
					.filter((s) => s.signerType === 'CLIENT')
					.forEach((s) => {
						result.set('Signer.FirstName', this.checkNull(s.firstName));
						result.set('Signer.LastName', this.checkNull(s.lastName));
						result.set('Signer.Email', this.checkNull(s.email));
						result.set('Signer.Title', this.checkNull(s.title));
					});

				let paymentPlan = this.getPaymentPlan(agreement);

				let groupedRecurring = this.getGroupedRecurring(agreement);

				if(paymentPlan){
					let firstPayment = paymentPlan.scheduledPayments[0];
					let recurringInvestment = this.getRecurringInvestment(agreement,true);
					let total = Number(firstPayment.calculatedAmount) + Number(recurringInvestment.totalPreTax) + Number(recurringInvestment.totalTax);
					result.set('UpFront.Total', this.$formatters.dollars(total, true, true, agreement.currency));
					result.set('UpFront.UnformattedTotal',total);
				}else {
					let totalUpFront = this.getTotalUpFront(agreement);
					result.set('UpFront.Total', this.$formatters.dollars(totalUpFront.totalPreTax + totalUpFront.totalTax, true, true, agreement.currency));
					result.set('UpFront.UnformattedTotal',totalUpFront.totalPreTax + totalUpFront.totalTax);
				}

				let cardProcessingFees = result.get('UpFront.UnformattedTotal');

				if (agreement.client?.whoPaysCardFees === 'Client') {
					cardProcessingFees = this.calculateAdditionalFee(agreement.account.baseCardFeeRate,cardProcessingFees);
				} else if (this.agreement.client?.whoPaysCardFees === 'Split') {
					cardProcessingFees = this.calculateAdditionalFee(agreement.account.splitCardFeeRate,cardProcessingFees);
				}else{
					cardProcessingFees = 0;
				}

				result.set('UpFront.CardProcessingFees', this.$formatters.dollars(cardProcessingFees, true, true, agreement.currency));

				let oneTimeTotal = this.getTotalUpFront(agreement,false);
				result.set('OneTime.Total', this.$formatters.dollars(oneTimeTotal.totalPreTax, true, true, agreement.currency));
				result.set('OneTime.TotalWithTax', this.$formatters.dollars(Number(oneTimeTotal.totalPreTax) + Number(oneTimeTotal.totalTax), true, true, agreement.currency));
				result.set('OneTime.Tax', this.$formatters.dollars(oneTimeTotal.totalTax, true, true, agreement.currency));

				let recurringTotal = 0;
				let recurringTaxAmount =0;

				groupedRecurring.forEach((value) => {
					// result.set(`Recurring.${key}.Total`,this.$formatters.dollars(value.total,true,true,agreement.currency));
					// result.set(`Recurring.${key}.TotalWithTax`,this.$formatters.dollars(value.taxAmount + value.total,true,true,agreement.currency));
					// result.set(`Recurring.${key}.Tax`,this.$formatters.dollars(value.taxAmount,true,true,agreement.currency));
					recurringTotal = recurringTotal + value.total;
					recurringTaxAmount = recurringTaxAmount + value.taxAmount;
				})

				result.set(`Recurring.Total`,this.$formatters.dollars(recurringTotal,true,true,agreement.currency));
				result.set(`Recurring.TotalWithTax`,this.$formatters.dollars(recurringTaxAmount + recurringTotal,true,true,agreement.currency));
				result.set(`Recurring.Tax`,this.$formatters.dollars(recurringTaxAmount,true,true,agreement.currency));
			}

			return result;
		},

		calculateAdditionalFee: function(feeDecimal, netAmount) {
			let totalInvoice = netAmount / (1 - feeDecimal);
			return totalInvoice - netAmount;
		},

		formatServiceItemDate: function(item) {
			if (item.relativeDueDate && item.dueDateRelative) {
				let timeUnit = this.timeUnits.find((u) => u.value === item.dueDateRelative.timeUnit);
				if (timeUnit && item.dueDateRelative.duration) {
					return item.dueDateRelative.duration + ' ' + timeUnit.text;
				} else {
					return '--';
				}
			} else if (item.dueDate) {
				return DateTime.fromISO(item.dueDate).toLocaleString(DateTime.DATE_FULL);
			} else {
				return '--';
			}
		},

		formatPackageFeeType: function(servicePackage) {
			if (servicePackage.fees.feeType === 'Retainer') {
				return servicePackage.fees.retainerSchedule + ' recurring';
			} else {
				return servicePackage.fees.feeType;
			}
		},

		formatServiceItemTotal: function(agreement, servicePackage, item) {
			if (item.addOnItem) {
				if (servicePackage.fees.feeType === 'Retainer' && !item.oneTimeFee) {
					return (
						this.$formatters.dollars(item.quantity * item.rate, true, true, agreement.currency) +
						'/' +
						servicePackage.fees.retainerSchedule.toLowerCase()
					);
				} else {
					return this.$formatters.dollars(item.quantity * item.rate, true, true, agreement.currency);
				}
			} else {
				if (servicePackage.fees.feeType === 'Per Item') {
					return `${this.$formatters.dollars(item.quantity * item.rate, true, true, agreement.currency)}`;
				} else {
					return '--';
				}
			}
		},

		getItemRate: function(item) {
			return item.quantity * item.rate;
		},

		getPackageTotal: function(agreement, servicePackage, taxableTotal = false) {
			if (servicePackage.fees.feeType !== 'Per Item') {
				let total = Number(0);

				if(!taxableTotal || servicePackage.fees.taxable){
					total = Number(servicePackage.fees.amount)
				}

				if(servicePackage.fees.feeType === 'Fixed Price'){
					servicePackage.items.forEach((i) => {
						if((i.addOnItem && i.optional && i.selected) || (i.addOnItem && !i.optional)){
							if(!taxableTotal || i.taxable) {
								total = total + (i.quantity * i.rate);
							}
						}
					});
				}else if(servicePackage.fees.feeType === 'Retainer'){
					servicePackage.items.forEach((i) => {
						if(i.oneTimeFee || !i.addOnItem){
							//do nothing
						}else if((i.addOnItem && i.optional && i.selected) || (i.addOnItem && !i.optional)){
							if(!taxableTotal || i.taxable) {
								total = total + (i.quantity * i.rate);
							}
						}
					});
				}
				return total;
			} else {
				let total = 0;
				servicePackage.items.forEach((i) => {
					if((i.addOnItem && i.optional && i.selected) || !i.addOnItem || !i.optional){
						if(!taxableTotal || i.taxable) {
							total = total + i.quantity * i.rate;
						}
					}
				});
				return total;
			}
		},

		getPackageTotalPreTax: function(agreement, servicePackage){
			return this.getPackageTotal(agreement,servicePackage,false);
		},

		getPackageTotalWithTax: function(agreement, servicePackage) {
			return this.getPackageTotal(agreement, servicePackage, false) + this.getPackageTax(agreement, servicePackage);
		},

		getPackageTax(agreement, servicePackage) {
			let taxRate = this.getTaxRate(agreement) / 100;
			return this.getPackageTotal(agreement, servicePackage, true) * taxRate;
		},

		getFeeLabel: function(servicePackage) {
			if (servicePackage.fees.feeType === 'Retainer') {
				return servicePackage.fees.retainerSchedule;
			} else {
				return servicePackage.fees.feeType;
			}
		},

		getCurrency: function(agreement) {
			if (agreement) {
				return agreement.currency;
			} else {
				return '';
			}
		},

		getTaxLabel: function(agreement) {
			if (agreement) {
				return agreement.account.taxLabel;
			} else {
				return '';
			}
		},

		getTaxRate: function(agreement) {
			if (agreement) {
				return Number((agreement.client?.defaultTaxRate || agreement.client?.defaultTaxRate === 0) ? agreement.client?.defaultTaxRate : agreement.account.defaultTaxRate);
			} else {
				return 0;
			}
		},

		getHourlyDepositAmount: function(servicePackage){
			let hours;
			let min = servicePackage.fees.estimateMin;
			let max = servicePackage.fees.estimateMax;

			if(min && max){
				hours = (min + max) / 2;
			}else{
				hours = min ? min : max;
			}

			return ((hours * servicePackage.fees.amount) * (servicePackage.depositAmount / 100));
		},

		getPerItemDepositAmount: function(servicePackage){
			if(servicePackage && servicePackage.items) {
				let itemTotal = 0;
				servicePackage.items.filter(i => !i.optional || (i.optional && i.selected)).forEach((i) => {
					itemTotal = itemTotal + (i.quantity * i.rate);
				});
				return (itemTotal) * (servicePackage.depositAmount / 100);
			}else{
				return 0;
			}
		},

		getDepositAmount: function(servicePackage){
			if(servicePackage.fees.feeType === 'Hourly') {
				return this.$formatters.dollars(this.getHourlyDepositAmount(servicePackage), true, true, this.agreement.currency);
			}else if(servicePackage.fees.feeType === 'Per Item'){
				return this.$formatters.dollars(this.getPerItemDepositAmount(this.element), true, true, this.agreement.currency);
			}else{
				return this.$formatters.dollars(0, true, true, this.agreement.currency);
			}
		},

		getRecurringInvestment: function(agreement, advancedOnly=false){
			let taxRate = this.getTaxRate(agreement) / 100;
			let selectedPackages = this.getSelectedPackages(agreement);

			let totals = {
				totalPreTax: 0,
				totalTax: 0
			}

			for(let i=0; i < selectedPackages.length; i++) {
				let selectedPackage = selectedPackages[i];
				let taxAmount = 0;
				let total = 0;

				if (selectedPackage.fees.feeType === 'Retainer') {
					if(selectedPackage.fees.retainerTiming === 'Arrears' && advancedOnly){
						continue;
					}
					total = selectedPackage.fees.amount;
					taxAmount = selectedPackage.fees.taxable ? (total * taxRate) : 0;

					selectedPackage.items.filter(i => i.addOnItem && ((i.optional && i.selected) || !i.optional) && !i.oneTimeFee).forEach(i => {
						total = total + (i.quantity * i.rate);
						taxAmount = taxAmount + (i.taxable ? ((i.quantity * i.rate) * taxRate) : 0);
					})

					totals.totalPreTax = totals.totalPreTax + total;
					totals.totalTax = totals.totalTax + taxAmount;
				}
			}

			return totals;
		},

		getTotalUpFront: function(agreement, includeRetainer = true) {
			let selectedPackages = this.getSelectedPackages(agreement);
			let taxRate = this.getTaxRate(agreement) / 100;

			let totals = {
				totalPreTax: 0,
				totalTax: 0
			}

			for(let i=0; i < selectedPackages.length; i++){
				let selectedPackage = selectedPackages[i];
				let taxAmount =0;
				let total = 0;

				if(selectedPackage.fees.feeType === 'Hourly'){
					total = this.getHourlyDepositAmount(selectedPackage);
					taxAmount = selectedPackage.fees.taxable ? (total * taxRate) : 0;
				}else if(includeRetainer && selectedPackage.fees.feeType === 'Retainer' && selectedPackage.fees.retainerTiming === 'Advanced'){
					total = selectedPackage.fees.amount;
					taxAmount = selectedPackage.fees.taxable ? (total * taxRate) : 0;
				}else if(selectedPackage.fees.feeType === 'Per Item'){
					selectedPackage.items.forEach((i) => {
						if((i.addOnItem && i.optional && i.selected) || !i.addOnItem || !i.optional){
							total = total + (i.quantity * i.rate);
							taxAmount = taxAmount + (i.taxable ? ((i.quantity * i.rate) * taxRate) : 0);
						}
					});
				}else if(selectedPackage.fees.feeType === 'Fixed Price'){
					total = Number(selectedPackage.fees.amount);
					taxAmount = selectedPackage.fees.taxable ? (total * taxRate) : 0;
				}

				if(selectedPackage.fees.feeType !== 'Per Item') {
					selectedPackage.items.filter(i => (i.addOnItem || i.oneTimeFee) && ((i.optional && i.selected) || !i.optional)).forEach(i => {
						if ((selectedPackage.fees.feeType === 'Retainer' && includeRetainer && !i.oneTimeFee) || i.oneTimeFee) {
							total = total + (i.quantity * i.rate);
							taxAmount = taxAmount + (i.taxable ? ((i.quantity * i.rate) * taxRate) : 0);
						}
					})
				}

				totals.totalPreTax = Number(totals.totalPreTax) + Number(total);
				totals.totalTax = Number(totals.totalTax) + Number(taxAmount);
			}

			return totals;
		},


		getGroupedRecurring: function(agreement) {
			let selectedPackages = this.getSelectedPackages(agreement);
			let taxRate = this.getTaxRate(agreement) / 100;
			let grouping = new Map();
			let schedulers = ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Bi-Annually', 'Annually'];

			for(let i=0; i < schedulers.length; i++){
				grouping.set(schedulers[i],{taxAmount:0,total:0})
			}

			for(let i=0; i < selectedPackages.length; i++){
				let selectedPackage = selectedPackages[i];
				let taxAmount =0;
				let total = 0;

				if(selectedPackage.fees.feeType === 'Retainer'){
					total = Number(selectedPackage.fees.amount);
					taxAmount = Number(selectedPackage.fees.taxable) ? (total * taxRate) : 0;
				}

				selectedPackage.items.filter(i => i.addOnItem && (i.optional ? i.selected : true) && !i.oneTimeFee).forEach(i => {
					total = total + (i.quantity * i.rate);
					taxAmount = taxAmount + (i.taxable ? ((i.quantity * i.rate) * taxRate) : 0);
				})

				let result = grouping.get(selectedPackage.fees.retainerSchedule);
				if(result){
					result.taxAmount = result.taxAmount + taxAmount;
					result.total = result.total + total;
				}
			}

			return grouping;
		},

		getSelectedPackages: function(agreement) {
			let services = this.getServices(agreement);
			let selectedPackages = [];

			for (let i = 0; i < services.length; i++) {
				let service = services[i];

				if (!service.packages.length) {
					continue;
				}

				if (service.selectionRequired && service.packages.length === 1) {
					selectedPackages.push(service.packages[0]);
				} else {
					selectedPackages.push(...service.packages.filter((p) => p && p.selected));
				}
			}

			return selectedPackages;
		},

		getServices: function(agreement) {
			return this.getItemsOfType(agreement,'Services');
		},

		getPaymentPlan: function(agreement){
			let paymentPlans = this.getItemsOfType(agreement,'PaymentPlan');
			if(paymentPlans.length){
				return paymentPlans[0];
			}else{
				return null;
			}
		},

		getItemsOfType: function(agreement,type){
			let result = [];

			for (let i = 0; i < agreement.pages.length; i++) {
				let page = agreement.pages[i];
				page.schema.forEach((item) => {
					if (item.type === type) {
						result.push(item);
					} else if (item.type === 'Container') {
						for (let j = 0; j < item.columnCount; j++) {
							item.columns[j].items.forEach((containerItem) => {
								if (containerItem.type === type) {
									result.push(containerItem);
								}
							});
						}
					}
				});
			}

			return result;
		}
	},

	computed: {
		clientFields: function() {
			if (this.$store.state.clientFields && this.$store.state.clientFields.fields) {
				return this.$store.state.clientFields.fields;
			} else {
				return [];
			}
		},

		pipelineFields: function() {
			if (this.$store.state.pipelineFields && this.$store.state.pipelineFields.fields) {
				return this.$store.state.pipelineFields.fields;
			} else {
				return [];
			}
		},

		contactFields: function() {
			if (this.$store.state.contactFields && this.$store.state.contactFields.fields) {
				return this.$store.state.contactFields.fields;
			} else {
				return [];
			}
		},
	},
};
